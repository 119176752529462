
import { Component, Vue, Prop } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { ArticleService, HealthProductService } from "src/services";
import tabbar from "src/components/tabbar";
import { tools } from "src/utils";
import { Payment_Type } from "src/enums";
import router from "src/router";
import globalConfig from "src/config/index";

@Component
export default class PackageComponent extends Vue {
  /**
   * 实物商品列表
   * @private
   * @returns void
   */
  private goodsList: Array<any> = [];

  /**
   * ids
   * @private
   * @returns Array<number>
   */
  private get ids() {
    let ids = [];
    if (localStorage.getItem("productList")) {
      ids = JSON.parse(localStorage.getItem("productList"));
    }
    return (this.$route.params && this.$route.params.ids) || ids;
  }

  /**
   * 实物
   * @private
   * @returns string
   */
  private get productType(): string {
    return (this.$route.query && (this.$route.query.productType as string)) || "";
  }

  /**
   * 医疗服务类型
   * @private
   * @returns string
   */
  private get type(): string {
    return (this.$route.query && (this.$route.query.type as string)) || "";
  }

  /**
   * 实物商品
   * @private
   * @returns void
   */
  protected onGoodsDetail(item: any): void {
    if (item.productType == 4) {
      this.$router.push({ name: "goodsDetail", query: { id: item.id } });
    } else {
      this.$router.push({ name: "medical", query: { id: item.id } });
    }
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    this.getHealthProductList();
  }

  /**
   * 获取实物列表
   * @private
   * @returns void
   */
  private async getHealthProductList(): Promise<void> {
    try {
      let { content: result } = await HealthProductService.instance.getHealthProductList({ productIds: this.ids });
      if (result.data) {
        this.goodsList = result.data || [];
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }
}
